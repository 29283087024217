import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsivePie } from '@nivo/pie'
import {
    TextField,
    FormControl,
    Button,
    Divider,
    Modal,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextareaAutosize,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Header from "./Header";
import { Ingrediente } from "../scenes/ricette/details/index";
import {
    useGetMealTypesQuery,
} from "../redux/state/api";
import { useForm, Controller } from "react-hook-form";
import IngredientAndQuantity from "./IngredientAndQuantity";

export function MealModal({
    openModal,
    setOpenModal,
    addMeal,
    mealWithSameMealTypeExist,
    isEditing = false,
    defaultName = "",
    defaultMealType = { id: 0, name_it: "" },
    defaultNotes = "",
    defaultMealItems = [],
    defaultMacronutrients = {
        carbohydrates: 0,
        proteins: 0,
        fats: 0,
        alcohol: 0,
        fibers: 0,
        sugars: 0,
    },
    mealSelected = true,
}) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1500,
        height: 600,
        bgcolor: "background.paper",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        p: 4,
        overflowX: "hidden",
    };
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        setError,
        clearErrors
    } = useForm();

    const [name, setName] = React.useState("");
    const [mealType, setMealType] = React.useState({ id: 0, name_it: "" });
    const [quantity, setQuantity] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [mealItems, setMealItems] = React.useState([]);
    const [macronutrients, setMacronutrients] = React.useState({
        carbohydrates: 0,
        proteins: 0,
        fats: 0,
        alcohol: 0,
        fibers: 0,
        sugars: 0,
    });

    const [isEditingIngredientQuantity, setIsEditingIngredientQuantity] = React.useState(false);
    const [editingIngredientId, setEditingIngredientId] = React.useState(null);
    const [tempQuantity, setTempQuantity] = React.useState(quantity);
    const [mealItemIndexWhereEditIngredientQuantity, setMealItemIndexWhereEditIngredientQuantity] = React.useState(null);
    const [isAddIngredientAlternative, setIsAddIngredientAlternative] = React.useState(null);
    const [quantityAlternative, setQuantityAlternative] = React.useState("");


    const { data: mealTypesData, isLoading: isMealTypesLoading } =
        useGetMealTypesQuery();


    const handleChangeMealType = (event) => {
        const id = event.target.value;
        const selectedMealType = mealTypesData.find((mealType) => mealType.id == id);
        setMealType({ id: selectedMealType.id, name_it: selectedMealType.name_it });
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleRemoveIngredient = (ingredientId, mealItemIndex) => {
        const mealItemsCopy = [...mealItems];
        const ingredientOptionsCopy = [...mealItemsCopy[mealItemIndex]];
        const updatedIngredients = ingredientOptionsCopy.filter(
            (meal_item) => meal_item.ingredient.id !== ingredientId
        );
        if (updatedIngredients.length === 1) {
            updatedIngredients[0].selected = true
        }
        if (updatedIngredients.length === 0) {
            mealItemsCopy.splice(mealItemIndex, 1)
        }
        else {
            mealItemsCopy[mealItemIndex] = updatedIngredients
        }
        setMealItems(mealItemsCopy);
    };

    const handleChangeNotes = (event) => {
        setNotes(event.target.value);
    };

    const handleNavigateBack = () => {
        setOpenModal(false);
    };

    const handleAddMeal = () => {
        if (watch("meal_type_radio").hasOwnProperty("name_it") && !!!watch("meal_type_radio")) {
            setError("meal_type_radio", {
                type: "manual",
                message: "Seleziona il tipo di pasto"
            });
            return;
        }
        clearErrors("meal_type_radio");
        const newMeal = {
            meal_types_id: Number(mealType.id),
            name_it: name || mealType.name_it,
            meal_items: mealItems,
            notes: notes,
            selected: isEditing ? mealSelected : !mealWithSameMealTypeExist(mealType.id),
            macronutrients: macronutrients,
        };

        setName("");
        setNotes("");
        setMealType({ id: 0, name_it: "" });
        setMealItems([]);
        setQuantity("");
        reset({ meal_type_radio: '' });
        addMeal(newMeal)
    };

    const handleEditIngredient = (ingredientId, mealItemIndex) => {
        const ingredientToEdit = mealItems[mealItemIndex].find((meal_item) => meal_item.ingredient.id === ingredientId);
        setTempQuantity(ingredientToEdit.quantity);
        setEditingIngredientId(ingredientId);
        setMealItemIndexWhereEditIngredientQuantity(mealItemIndex);
        setIsEditingIngredientQuantity(true);
    };

    const handleSaveEditIngredientQuantity = (mealItemIndex) => {
        if (editingIngredientId === null) {
            // Handle the case where no ingredient is currently being edited
            return;
        }
        const mealItemsCopy = [...mealItems];
        const ingredientOptionsCopy = [...mealItemsCopy[mealItemIndex]];
        const ingredientsToEdit = ingredientOptionsCopy.map((meal_item) =>
            meal_item.ingredient.id === editingIngredientId ? { ...meal_item, quantity: tempQuantity } : meal_item
        )
        mealItemsCopy[mealItemIndex] = ingredientsToEdit
        setMealItems(mealItemsCopy)
        setIsEditingIngredientQuantity(false);
    };

    const handleCancelEditIngredientQuantity = () => {
        setIsEditingIngredientQuantity(false);
        setEditingIngredientId(null);
        setTempQuantity(quantity);
    };

    const handleMakeIngredientOptionSelected = (ingredientId, mealItemIndex) => {
        const mealItemsCopy = [...mealItems];
        const ingredientOptionsCopy = [...mealItemsCopy[mealItemIndex]];
        const ingredientsToEdit = ingredientOptionsCopy.map((meal_item) =>
            meal_item.ingredient.id === ingredientId ? { ...meal_item, selected: true } : { ...meal_item, selected: false }
        )
        mealItemsCopy[mealItemIndex] = ingredientsToEdit
        setMealItems(mealItemsCopy)
    }

    const handleAddIngredientAlternativeSearchBar = (index) => {
        setIsAddIngredientAlternative(index)
    }

    React.useEffect(() => {
        if (isEditing && mealTypesData) {
            const initialMealType = mealTypesData.find((mealType) => mealType.id == defaultMealType);
            setName(defaultName);
            setMealType(initialMealType);
            setNotes(defaultNotes);
            setMealItems(defaultMealItems);
            setMacronutrients(defaultMacronutrients);
        }
    }, [isEditing, defaultName, defaultMealType, defaultNotes, defaultMealItems, defaultMacronutrients, mealTypesData]);


    const nutrientPercentage = (nutrient, energy, factor) => {
        return parseFloat((100 * nutrient * factor / energy).toFixed(2));
    }
    const nutrientQuantity = (nutrient, quantity) => {
        return parseFloat(((nutrient * quantity) / 100).toFixed(2));
    }

    React.useEffect(() => {
        const sumMacronutrients = mealItems.reduce(
            (acc, ingredientOptions) => {
                const ingredient = ingredientOptions.find((ing) => ing.selected === true)
                if (ingredient) {
                const selectedIngredient = ingredient.ingredient
                    if (selectedIngredient) {
                        acc.carbohydrates += nutrientQuantity(selectedIngredient.carbohydrates, ingredient.quantity);
                        acc.proteins += nutrientQuantity(selectedIngredient.proteins, ingredient.quantity);
                        acc.fats += nutrientQuantity(selectedIngredient.fats, ingredient.quantity);
                        acc.alcohol += nutrientQuantity(selectedIngredient.alcohol, ingredient.quantity);
                        acc.fibers += nutrientQuantity(selectedIngredient.fibers, ingredient.quantity);
                        acc.sugars += nutrientQuantity(selectedIngredient.sugars, ingredient.quantity);
                        acc.energy += nutrientQuantity(selectedIngredient.energy, ingredient.quantity);
                    }
                }
                return acc;
            },
            {
                carbohydrates: 0.0,
                proteins: 0.0,
                fats: 0.0,
                alcohol: 0.0,
                fibers: 0.0,
                sugars: 0.0,
                energy: 0.0
            }
        );
        const totalNutrient = sumMacronutrients.carbohydrates + sumMacronutrients.proteins + sumMacronutrients.fats + sumMacronutrients.alcohol;
        const totalMacronutrients = {
            carbohydrates: {
                quantity: parseFloat(sumMacronutrients.carbohydrates.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.carbohydrates, sumMacronutrients.energy, 4) - nutrientPercentage(sumMacronutrients.sugars, sumMacronutrients.energy, 4)
            },
            proteins: {
                quantity: parseFloat(sumMacronutrients.proteins.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.proteins, sumMacronutrients.energy, 4)
            },
            fats: {
                quantity: parseFloat(sumMacronutrients.fats.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.fats, sumMacronutrients.energy, 9)
            },
            alcohol: {
                quantity: parseFloat(sumMacronutrients.alcohol.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.alcohol, sumMacronutrients.energy, 7)
            },
            fibers: {
                quantity: parseFloat(sumMacronutrients.fibers.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.fibers, sumMacronutrients.energy, 0)
            },
            sugars: {
                quantity: parseFloat(sumMacronutrients.sugars.toFixed(2)),
                percentage: nutrientPercentage(sumMacronutrients.sugars, sumMacronutrients.energy, 4)
            },
            energy: {
                quantity: parseFloat(sumMacronutrients.energy.toFixed(2)),
            },
        }

        setMacronutrients(totalMacronutrients);
    }, [mealItems]);

    if (isMealTypesLoading) {
        return <div className="loader-container">
            <div className="loader"></div>
        </div>
            ;
    }

    return <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
            <Header title={isEditing ? "Modifica pasto" : "Creazione pasto"} />
            <Box mt="20px" display="flex" gap="3rem">
                <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 500, ml: "-5px" }}
                >
                    <TextField
                        id="nome"
                        label="Nome"
                        variant="filled"
                        color="secondary"
                        value={name}
                        onChange={handleChangeName}
                    />
                </FormControl>
                <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 300 }}
                    color="secondary"
                >
                    <FormLabel id="tipPasto">Tipologia di Pasto</FormLabel>
                    <Controller
                        name="meal_type_radio"
                        control={control}
                        defaultValue={mealType}
                        rules={{ required: "Campo obbligatorio" }}
                        render={({ field }) => (
                            <RadioGroup
                                column
                                aria-labelledby="tipPasto"
                                name="meal_type_radio"
                                required
                                {...field}
                                onChange={(e) => {
                                    handleChangeMealType(e);
                                    field.onChange(e);
                                }}
                            >
                                {mealTypesData.map(({ id, name_it }) => (
                                    <FormControlLabel
                                        value={id}
                                        key={id}
                                        control={<Radio color="secondary" />}
                                        label={name_it}
                                        checked={id === mealType.id}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                    {errors.meal_type_radio && <p style={{ color: "red" }}>{errors.meal_type_radio.message}</p>}
                </FormControl>
                <Box display="flex" justifyContent="flex-start" width="50%">
                    <Box
                        sx={{
                            width: "100%",
                            height: "332px",
                            borderRadius: "6px",
                            border: "1px solid rgba(193, 199, 205, 1)",
                        }}
                    >
                        <Box display="flex">
                            <Box mt="40px" ml="40px" >
                                <Typography
                                    color="#464868"
                                    fontSize="20px"
                                    fontWeight="700"
                                    variant="h1"
                                >
                                    Macronutrienti
                                </Typography>
                            </Box>
                        </Box>
                        <ResponsivePie
                            colors={["#EDC047", "#4496A8", "#ED8047", "#BF2B2B", "#F2E68A", "#ffffff"]}
                            tooltip={e => {
                                let { datum } = e; return <BasicTooltip
                                    id={datum.id}
                                    value={`${datum.formattedValue}%`}
                                    enableChip={true}
                                    color={datum.color}
                                />
                            }}
                            data={
                                [
                                    {
                                        id: "Carbo",
                                        value: macronutrients.carbohydrates.percentage,
                                        label: `Carbo ${macronutrients.carbohydrates.quantity}g`,
                                    },
                                    {
                                        id: "Proteine",
                                        value: macronutrients.proteins.percentage,
                                        label: `Proteine ${macronutrients.proteins.quantity}g`,
                                    },
                                    {
                                        id: "Grassi",
                                        value: macronutrients.fats.percentage,
                                        label: `Grassi ${macronutrients.fats.quantity}g`,
                                    },
                                    {
                                        id: "Alcol",
                                        value: macronutrients.alcohol.percentage,
                                        label: `Alcol ${macronutrients.alcohol.quantity}g`,
                                    },
                                    {
                                        id: "Zuccheri",
                                        value: macronutrients.sugars.percentage,
                                        label: `Zuccheri ${macronutrients.sugars.quantity}g`,
                                    },
                                    {
                                        id: "Fibre",
                                        label: `Fibre ${macronutrients.fibers.quantity}g`,
                                    },
                                ]}
                            margin={{ top: 30, right: 90, bottom: 100, left: -150 }}
                            innerRadius={0.4}
                            enableArcLinkLabels={false}
                            padAngle={1}
                            cornerRadius={5}
                            enableArcLabels={false}
                            theme={{ legends: { text: { fontSize: 18, fontFamily: "Geomanist" } } }}
                            activeOuterRadiusOffset={8}
                            legends={[
                                {
                                    anchor: 'right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: -10,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#464868',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 16,
                                    symbolShape: 'square',
                                }
                            ]}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider
                sx={{
                    marginTop: "32px",
                    marginLeft: "-5px",
                    bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                    border: 0, // Rimuove il bordo predefinito
                    borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                }}
            />
            <Box mt="32px">
                {mealItems.map((ingredientoptions, mealitemsindex) => {
                    return <Box>
                        {ingredientoptions.map(({ ingredient: selectedIngredient, quantity, selected }) => {
                            return (
                                <Box display="flex" gap="1rem" key={selectedIngredient.id}>
                                    <Ingrediente
                                        name={selectedIngredient ? selectedIngredient.simple_name_it : ""}
                                        specific_info={
                                            selectedIngredient ? selectedIngredient.specific_info : ""
                                        }
                                        quantity={quantity}
                                        energy={nutrientQuantity(selectedIngredient.energy, quantity)}
                                        selected={selected}
                                        categories_id={selectedIngredient.categories_id}
                                    />
                                    <Box sx={{ mt: "12px", width: "480px" }} >
                                        {!selected ? (
                                            <Button sx={{ color: 'black' }} onClick={() => handleMakeIngredientOptionSelected(selectedIngredient.id, mealitemsindex)}>
                                                Rendi predefinito
                                            </Button>
                                        ) : <Box></Box>}
                                    </Box>
                                    <Box
                                        mt="20px"
                                        display="inherit"
                                        gap="1rem"
                                        onClick={() => handleEditIngredient(selectedIngredient.id, mealitemsindex)}
                                    >
                                        <EditIcon
                                            sx={{ cursor: "pointer", color: "primary" }}
                                        />
                                    </Box>
                                    <Modal open={isEditingIngredientQuantity} onClose={handleCancelEditIngredientQuantity}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: 300,
                                                bgcolor: 'background.paper',
                                                boxShadow: 24,
                                                p: 4,
                                            }}
                                        >
                                            <TextField
                                                label="Quantità"
                                                type="number"
                                                value={tempQuantity}
                                                onChange={(e) => setTempQuantity(e.target.value)}
                                            />
                                            <Button onClick={() => handleSaveEditIngredientQuantity(mealItemIndexWhereEditIngredientQuantity)}>Salva</Button>
                                            <Button onClick={() => handleCancelEditIngredientQuantity()}>Cancella</Button>
                                        </Box>
                                    </Modal>
                                    <Box
                                        mt="20px"
                                        display="inherit"
                                        gap="1rem"
                                        onClick={() => handleRemoveIngredient(selectedIngredient.id, mealitemsindex)}
                                    >
                                        <HighlightOffIcon
                                            sx={{ cursor: "pointer", color: "red" }}
                                        />
                                    </Box>
                                </Box>
                            );

                        })}

                        {ingredientoptions.length > 0 && (
                            <Box>
                                <Button
                                    sx={{
                                        textDecoration: 'underline', color: "#68BE6A",
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        mt: "16px",
                                        boxShadow: 'none',
                                        '&:hover, &:active, &:focus': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                    onClick={() => handleAddIngredientAlternativeSearchBar(mealitemsindex)}>
                                    aggiungi alternativa
                                </Button>
                                {isAddIngredientAlternative === mealitemsindex && (
                                    <IngredientAndQuantity
                                        ingredients={mealItems}
                                        quantity={quantityAlternative}
                                        setIngredients={setMealItems}
                                        setQuantity={setQuantityAlternative}
                                        isMealItem={true}
                                        isMainMealItem={false}
                                        mealItemsIndex={mealitemsindex}
                                        setIsAddIngredientAlternative={setIsAddIngredientAlternative}
                                        title="Aggiungi Alternativa Ingrediente"
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                })}
                <IngredientAndQuantity
                    ingredients={mealItems}
                    quantity={quantity}
                    setIngredients={setMealItems}
                    setQuantity={setQuantity}
                    isMealItem={true}
                    isMainMealItem={true}
                />
            </Box>
            <Divider
                sx={{
                    marginTop: "32px",
                    marginLeft: "-5px",
                    bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                    border: 0, // Rimuove il bordo predefinito
                    borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                }}
            />
            <Typography
                color="#000"
                fontSize="18px"
                fontWeight="700"
                variant="h1"
                sx={{ mt: "50px", ml: "8px" }}
            >
                Note
            </Typography>
            <Box display="flex" sx={{ flexDirection: "row" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
                    <TextareaAutosize
                        minRows={5}
                        placeholder="Aggiungi note pasto"
                        variant="filled"
                        color="secondary"
                        defaultValue={notes}
                        onChange={(event) => handleChangeNotes(event)}
                    />
                </FormControl>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="32px">
                <Button
                    sx={{
                        color: "#464868",
                        textDecoration: "underline",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                    onClick={handleNavigateBack}
                >
                    Annulla
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                        textTransform: 'none',
                        fontSize: "14px",
                        borderRadius: "8px",
                        padding: "8px 16px",
                        color: "white",
                        fontWeight: 600,
                        boxShadow: 'none',
                        '&:hover, &:active, &:focus': {
                            boxShadow: 'none'
                        }
                    }}

                    //TODO: controlla che ci sia almeno un ingrediente inserito in mealItems 
                    onClick={handleSubmit(handleAddMeal)}
                >
                    {isEditing ? "Modifica Pasto" : "Aggiungi Pasto"}
                </Button>
            </Box>
        </Box>
    </Modal>
}