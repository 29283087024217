import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsivePie } from '@nivo/pie'
import {
    FormControl,
    Button,
    Modal,
    IconButton,
    FormLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Header from "./Header";
import { Ingrediente } from "../scenes/ricette/details/index";
import { a11yProps, CustomTabPanel, StyledTab, StyledTabs } from './Tabs'
import { MealModal } from "./MealModal";

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function MealPlanTable({ numberOfDays, mealsData, setMealsData, isEditing }) {

    const [dayValue, setDayValue] = React.useState(0);
    const [openMealCreateModal, setOpenMealCreateModal] = React.useState(false);
    const [openMealEditModal, setOpenMealEditModal] = React.useState(false);
    const [dailyMacronutrients, setDailyMacronutrients] = React.useState(
        Array.from({ length: numberOfDays }, () => ({
            carbohydrates: 0,
            proteins: 0,
            fats: 0,
            alcohol: 0,
            fibers: 0,
            sugars: 0,
            energy: 0
        }))
    );

    const [openClearDayDialog, setOpenClearDayDialog] = React.useState(false);
    const [openDuplicateDayModal, setOpenDuplicateDayModal] = React.useState(false);
    const [openDuplicateMealModal, setOpenDuplicateMealModal] = React.useState(false);
    const [selectedDuplicateDayCheckboxes, setSelectedDuplicateDayCheckboxes] = React.useState([]);
    const [mealToEdit, setMealToEdit] = React.useState({});
    const [mealIndexToEdit, setMealIndexToEdit] = React.useState(0);
    const [mealToCopy, setMealToCopy] = React.useState({});
    const [generateRecipeCheckboxes, setGenerateRecipeCheckboxes] = React.useState(Array.from({ length: numberOfDays }, () => []));

    const handleChange = (event, newValue) => {
        setDayValue(newValue);
    };

    const dayIndices = Array.from({ length: numberOfDays }, (_, index) => index);


    const handleRemoveMeal = (dayIndex, mealIndex) => {
        const updatedMealsByDay = [...mealsData];
        const removedMealIsSelected = updatedMealsByDay[dayIndex][mealIndex].selected;
        updatedMealsByDay[dayIndex].splice(mealIndex, 1);
        if (removedMealIsSelected && updatedMealsByDay[dayIndex].length > 0) {
            updatedMealsByDay[dayIndex][0].selected = true;
        }
        const updatedGenerateRecipeCheckboxes = [...generateRecipeCheckboxes];
        updatedGenerateRecipeCheckboxes[dayValue].splice(mealIndex, 1);
        setGenerateRecipeCheckboxes(updatedGenerateRecipeCheckboxes);
        setMealsData(updatedMealsByDay);
    };

    const handleOnClickEditMeal = (dayIndex, mealIndex) => {
        setMealToEdit(mealsData[dayIndex][mealIndex])
        setMealIndexToEdit(mealIndex)
        setOpenMealEditModal(true)
        return;
    }

    const handleOpenClearDayDialog = () => {
        setOpenClearDayDialog(true);
    };

    const handleCloseClearDayDialog = () => {
        setOpenClearDayDialog(false);
    };

    const handleClearDay = (dayIndex) => {
        setOpenClearDayDialog(false);
        const mealsByDayCopy = [...mealsData];
        mealsByDayCopy[dayIndex] = [];
        const updatedGenerateRecipeCheckboxes = [...generateRecipeCheckboxes];
        updatedGenerateRecipeCheckboxes[dayValue] = [];
        setGenerateRecipeCheckboxes(updatedGenerateRecipeCheckboxes);
        setMealsData(mealsByDayCopy)
    };

    const handleOpenDuplicateDayModal = () => {
        setOpenDuplicateDayModal(true)
    }
    const handleOpenDuplicateMealModal = (dayIndex, mealIndex) => {
        setMealToCopy(mealsData[dayIndex][mealIndex])
        setOpenDuplicateMealModal(true)
    }
    const handleCheckboxDuplicateDayChange = (dayIndex) => {
        setSelectedDuplicateDayCheckboxes((prevSelected) => {
            if (prevSelected.includes(dayIndex)) {
                return prevSelected.filter((item) => item !== dayIndex);
            } else {
                return [...prevSelected, dayIndex];
            }
        });
    }

    const handleCheckboxGenerateRecipe = (dayIndex, mealIndex) => {
        setGenerateRecipeCheckboxes(prevState => {
            const newState = [...prevState]; // Copy the state array
            newState[dayIndex] = [...newState[dayIndex]]; // Copy the inner array for the specific day
            newState[dayIndex][mealIndex] = !newState[dayIndex][mealIndex]; // Update the checkbox state
            return newState;
        });
        const mealsByDayCopy = [...mealsData];
        mealsByDayCopy[dayIndex][mealIndex].generate_recipe = !mealsByDayCopy[dayIndex][mealIndex].generate_recipe
        setMealsData(mealsByDayCopy);
    }
    const handleDuplicateDay = () => {
        const mealsByDayCopy = mealsData.map((day) =>
            day.map((meal) => ({ ...meal, days_id: meal.days_id }))
        );
        const updatedGenerateRecipeCheckboxes = [...generateRecipeCheckboxes];
        selectedDuplicateDayCheckboxes.forEach((checkedDayIndex) => {
            mealsByDayCopy[checkedDayIndex] = mealsData[dayValue].map((meal) => ({
                ...meal,
                days_id: checkedDayIndex + 1,
            }));
            updatedGenerateRecipeCheckboxes[checkedDayIndex] = updatedGenerateRecipeCheckboxes[dayValue];
        });
        setGenerateRecipeCheckboxes(updatedGenerateRecipeCheckboxes);
        setMealsData(mealsByDayCopy)
        setSelectedDuplicateDayCheckboxes([])
        setOpenDuplicateDayModal(false)
    }
    const handleDuplicateMeal = () => {
        const updatedMealsByDay = [...mealsData];
        const newMeal = { ...mealToCopy };

        selectedDuplicateDayCheckboxes.forEach((checkedDayIndex) => {
            if (updatedMealsByDay[checkedDayIndex]
                .filter(
                    meal => meal.meal_types_id === newMeal.meal_types_id
                )
                .length > 0
            ) {
                newMeal.selected = false;
            }
            updatedMealsByDay[checkedDayIndex].push(newMeal);
            updatedMealsByDay[checkedDayIndex].sort((obj1, obj2) => obj1.meal_types_id - obj2.meal_types_id);
        });
        setMealsData(updatedMealsByDay);
        setSelectedDuplicateDayCheckboxes([])
        setOpenDuplicateMealModal(false)
    }

    const handleAddMeal = (newMeal) => {
        newMeal.days_id = dayValue + 1
        const updatedMealsByDay = [...mealsData];
        if (updatedMealsByDay[dayValue]) {
            updatedMealsByDay[dayValue].push(newMeal);
        }
        else {
            updatedMealsByDay[dayValue] = [newMeal];
        }
        updatedMealsByDay[dayValue].sort((obj1, obj2) => obj1.meal_types_id - obj2.meal_types_id);
        const updatedGenerateRecipeCheckboxes = [...generateRecipeCheckboxes];
        updatedGenerateRecipeCheckboxes[dayValue].push(false);
        setGenerateRecipeCheckboxes(updatedGenerateRecipeCheckboxes);
        setMealsData(updatedMealsByDay);
        setOpenMealCreateModal(false);
    };

    const handleEditMeal = (editedMeal) => {
        const updatedMealsByDay = [...mealsData];
        updatedMealsByDay[dayValue][mealIndexToEdit] = editedMeal;
        updatedMealsByDay[dayValue].sort((obj1, obj2) => obj1.meal_types_id - obj2.meal_types_id);

        setMealsData(updatedMealsByDay);
        setOpenMealEditModal(false);
    };

    const mealWithSameMealTypeExist = (mealType) => {
        return mealsData[dayValue] && mealsData[dayValue].some(meal => meal.meal_types_id === Number(mealType));
    }

    const nutrientPercentage = (nutrient, energy, factor) => {
        return parseFloat((100 * nutrient * factor / energy).toFixed(2));

    }
    const nutrientQuantity = (nutrient, quantity) => {
        return parseFloat(((nutrient * quantity) / 100).toFixed(2));
    }

    React.useEffect(() => {
        let newDailyMacronutrients = Array.from({ length: numberOfDays }, () => ({
            carbohydrates: {
                quantity: 0,
                percentage: 0.0
            },
            proteins: {
                quantity: 0,
                percentage: 0.0
            },
            fats: {
                quantity: 0,
                percentage: 0.0
            },
            alcohol: {
                quantity: 0,
                percentage: 0.0
            },
            fibers: {
                quantity: 0,
                percentage: 0.0
            },
            sugars: {
                quantity: 0,
                percentage: 0.0
            },
            energy: {
                quantity: 0,
            },
        }));


        mealsData.forEach((meals, dayIndex) => {
            meals.forEach((meal) => {
                if (meal.selected) {
                    newDailyMacronutrients[dayIndex].carbohydrates.quantity += meal.macronutrients.carbohydrates.quantity
                    newDailyMacronutrients[dayIndex].proteins.quantity += meal.macronutrients.proteins.quantity
                    newDailyMacronutrients[dayIndex].fats.quantity += meal.macronutrients.fats.quantity
                    newDailyMacronutrients[dayIndex].alcohol.quantity += meal.macronutrients.alcohol.quantity
                    newDailyMacronutrients[dayIndex].fibers.quantity += meal.macronutrients.fibers.quantity
                    newDailyMacronutrients[dayIndex].sugars.quantity += meal.macronutrients.sugars.quantity
                    newDailyMacronutrients[dayIndex].energy.quantity += meal.macronutrients.energy.quantity
                }
            });
        });
        newDailyMacronutrients = newDailyMacronutrients.map(newDailyMacronutrient => {
            const totalNutrient = (
                newDailyMacronutrient.carbohydrates.quantity +
                newDailyMacronutrient.proteins.quantity +
                newDailyMacronutrient.fats.quantity +
                newDailyMacronutrient.alcohol.quantity
            );
            const totalMacronutrients = {
                carbohydrates: {
                    quantity: parseFloat(newDailyMacronutrient.carbohydrates.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.carbohydrates.quantity, newDailyMacronutrient.energy.quantity, 4) - nutrientPercentage(newDailyMacronutrient.sugars.quantity, newDailyMacronutrient.energy.quantity, 4)
                },
                proteins: {
                    quantity: parseFloat(newDailyMacronutrient.proteins.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.proteins.quantity, newDailyMacronutrient.energy.quantity, 4)
                },
                fats: {
                    quantity: parseFloat(newDailyMacronutrient.fats.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.fats.quantity, newDailyMacronutrient.energy.quantity, 9)
                },
                alcohol: {
                    quantity: parseFloat(newDailyMacronutrient.alcohol.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.alcohol.quantity, newDailyMacronutrient.energy.quantity, 7)
                },
                fibers: {
                    quantity: parseFloat(newDailyMacronutrient.fibers.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.fibers.quantity, newDailyMacronutrient.energy.quantity, 0)
                },
                sugars: {
                    quantity: parseFloat(newDailyMacronutrient.sugars.quantity.toFixed(2)),
                    percentage: nutrientPercentage(newDailyMacronutrient.sugars.quantity, newDailyMacronutrient.energy.quantity, 4)
                },
                energy: {
                    quantity: parseFloat(newDailyMacronutrient.energy.quantity.toFixed(2)),
                },
            }
            return totalMacronutrients;
        })


        setDailyMacronutrients(newDailyMacronutrients);
    }, [mealsData, numberOfDays]);

    const theme = useTheme();
    // Media query che controlla la larghezza della viewport
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const boxStyle = {
        position: 'absolute',
        left: matches ? '200px' : '47.5%', // Fisso a 200px per schermi piccoli
        display: 'flex',
        justifyContent: 'flex-end',
        width: '50%',
    };

    return (
        <Box sx={{ width: "100%", marginTop: "12px", marginLeft: "-18px" }}>
            <Box display="flex" justifyContent="space-between" sx={{ marginLeft: "8px" }}>
                <Box>
                    <Box>
                        <StyledTabs
                            value={dayValue}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                        >
                            {dayIndices.map((dayIndex) => (
                                <StyledTab
                                    key={dayIndex}
                                    label={`Giorno ${dayIndex + 1}`}
                                    {...a11yProps(dayIndex)}
                                />
                            ))}
                        </StyledTabs>
                    </Box>
                    {dayIndices.map((dayIndex) => (
                        <CustomTabPanel key={dayIndex} value={dayValue} index={dayIndex}>
                            {/* Content of the CustomTabPanel for each day */}
                            {/* You can replace this with your specific content */}
                            {/* For example: */}

                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    color="#2F1847"
                                    fontSize="24px"
                                    fontWeight="700"
                                    variant="h1"
                                    sx={{ mt: "6px", ml: "-15px" }}
                                >
                                    Giorno {dayIndex + 1}{" "}
                                </Typography>
                                <Box sx={boxStyle}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" >
                                        {/* Box per contenere i bottoni e mantenere lo spazio e l'allineamento */}
                                        <Button
                                            sx={{ color: "#68BE6A", mr: 2, whiteSpace: "nowrap" }} // Aggiunta della proprietà white-space
                                            startIcon={<ContentCopyIcon />}
                                            onClick={handleOpenDuplicateDayModal}
                                        >
                                            Duplica Giorno
                                        </Button>
                                        <Button
                                            color="error"
                                            startIcon={<ClearIcon />}
                                            onClick={handleOpenClearDayDialog}
                                            sx={{ ml: 2, whiteSpace: "nowrap" }} // Aggiunta della proprietà white-space
                                        >
                                            Svuota giorno
                                        </Button>
                                    </Box>
                                    <Modal open={openDuplicateDayModal} onClose={() => setOpenDuplicateDayModal(false)}>
                                        <Box sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "30%",
                                            height: "50%",
                                            bgcolor: "background.paper",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                            p: 4,
                                            overflowX: "hidden",
                                        }}>
                                            <Header title="Duplica Giorno" />
                                            <Box mt="20px" display="flex" gap="3rem">
                                                <FormControl
                                                    variant="standard"
                                                    sx={{ m: 1, minWidth: 400 }}
                                                    color="secondary"
                                                >
                                                    <FormLabel id="dayToDuplicate">Seleziona i giorni sui quali vuoi copiare i pasti</FormLabel>
                                                    {dayIndices.map((dayIndex) => (
                                                        dayIndex !== dayValue && (
                                                            <Box key={`day-index-${dayIndex}`}>
                                                                <Checkbox
                                                                    checked={selectedDuplicateDayCheckboxes.includes(dayIndex)}
                                                                    onChange={() => handleCheckboxDuplicateDayChange(dayIndex)}
                                                                />
                                                                {`Giorno ${dayIndex + 1}`}
                                                            </Box>
                                                        )
                                                    )
                                                    )}
                                                    <div className="gradient-border-wrapper">
                                                        <Button
                                                            sx={{
                                                                color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                                                                fontWeight: 600,
                                                                textTransform: 'none',
                                                                width: "100%", // Ensure the button takes up the full width
                                                            }}
                                                            className="gradient-button"
                                                            onClick={handleDuplicateDay}>
                                                            Duplica Giorno
                                                        </Button>
                                                    </div>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Modal>
                                    <Dialog open={openClearDayDialog} onClose={handleCloseClearDayDialog}>
                                        <DialogTitle>Confermi di voler svuotare il giorno</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Cancellerai tutti i pasti inseriti.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseClearDayDialog} color="error">
                                                Annulla
                                            </Button>
                                            <Button onClick={() => handleClearDay(dayIndex)} sx={{
                                                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                                                textTransform: 'none',
                                                color: 'white',
                                                fontWeight: 600,
                                                boxShadow: 'none',
                                                '&:hover, &:active, &:focus': {
                                                    boxShadow: 'none'
                                                }
                                            }}>
                                                Conferma
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box sx={{ ml: "-16px"}}>
                                    {mealsData[dayIndex] &&
                                        mealsData[dayIndex].map((meal, mealIndex) => (
                                        <Box
                                            key={`meal-box-${mealIndex}`}
                                            sx={{
                                                border: "3px solid rgba(193, 199, 205, 0.50)",
                                                borderRadius: "6px",
                                                opacity: !meal.selected ? 0.5 : 1.0
                                            }}
                                            mt="20px"
                                        >
                                                <Modal open={openDuplicateMealModal} onClose={() => setOpenDuplicateMealModal(false)}>
                                                    <Box sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        width: "30%",
                                                        height: "50%",
                                                        bgcolor: "background.paper",
                                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                        p: 4,
                                                        overflowX: "hidden",
                                                    }}>
                                                        <Header title="Duplica Pasto" />
                                                        <Box mt="20px" display="flex" gap="3rem">
                                                            <FormControl
                                                                variant="standard"
                                                                sx={{ m: 1, minWidth: 400 }}
                                                                color="secondary"
                                                            >
                                                                <FormLabel id="dayToDuplicateMealOn">Seleziona i giorni sui quali vuoi copiare il pasto</FormLabel>
                                                                {dayIndices.map((dayIndex) => (
                                                                    dayIndex !== dayValue && (
                                                                        <Box key={`day-index-${dayIndex}`}>
                                                                            <Checkbox
                                                                                checked={selectedDuplicateDayCheckboxes.includes(dayIndex)}
                                                                                onChange={() => handleCheckboxDuplicateDayChange(dayIndex)}
                                                                            />
                                                                            {`Giorno ${dayIndex + 1}`}
                                                                        </Box>
                                                                    )
                                                                )
                                                                )}
                                                                <div className="gradient-border-wrapper">
                                                                    <Button
                                                                        sx={{
                                                                            color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                                                                            fontWeight: 600,
                                                                            textTransform: 'none',
                                                                            width: "100%", // Ensure the button takes up the full width
                                                                        }}
                                                                        className="gradient-button"
                                                                        onClick={() => handleDuplicateMeal()}>
                                                                        Duplica Pasto
                                                                    </Button>
                                                                </div>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                                <Box display="flex" justifyContent="space-between">
                                                <Typography
                                                    variant="h1"
                                                    fontSize="20px"
                                                    fontWeight="700"
                                                    sx={{ color: "#2F1847", m: 2 }}
                                                >
                                                    {meal.name_it}
                                                    </Typography>
                                                    <Box display="flex" justifyContent="space-between">
                                                    <Typography
                                                    color="rgba(193, 199, 205, 1)"
                                                    fontSize="12px"
                                                    fontWeight="700"
                                                    variant="h1"
                                                    sx={{ color: "rgba(193, 199, 205, 1)", ml: 2, mt: 2.6, mr: 1.5 }}
                                                >
                                                    {/* {computeMealEnergy(meal)}kcal */}
                                                    {meal.macronutrients.energy.quantity}kcal
                                                </Typography>
                                                        <IconButton
                                                            onClick={() => handleOpenDuplicateMealModal(dayIndex, mealIndex)}
                                                        >
                                                            <ContentCopyIcon sx={{ cursor: "pointer", color: "#68BE6A" }} />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleOnClickEditMeal(dayIndex, mealIndex)}
                                                        >
                                                            <EditIcon sx={{ cursor: "pointer", color: "#68BE6A" }} />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleRemoveMeal(dayIndex, mealIndex)}
                                                        >
                                                            <ClearIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Box>
                                            </Box>

                                            {meal.meal_items.map((item, itemIndex) => (
                                                <Box key={`meal-item-box-${itemIndex}`}>
                                                    {item.map(
                                                        (ingredientOption, optionIndex) => {
                                                            const selectedIngredient = ingredientOption.ingredient
                                                            if (selectedIngredient) {
                                                                return (
                                                                    <Box display="flex" justifyContent="space-between" sx={{ m: 2 }}>
                                                                        <Ingrediente
                                                                            name={
                                                                                selectedIngredient
                                                                                    ? selectedIngredient.simple_name_it
                                                                                    : ""
                                                                            }
                                                                            specific_info={
                                                                                selectedIngredient
                                                                                    ? selectedIngredient.specific_info
                                                                                    : ""
                                                                            }
                                                                            quantity={ingredientOption.quantity}
                                                                            selected={ingredientOption.selected}
                                                                        
                                                                            energy={nutrientQuantity(selectedIngredient.energy, ingredientOption.quantity)}
                                                                            categories_id={selectedIngredient.categories_id}
                                                                        />
                                                                    </Box>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        }
                                                    )}
                                                </Box>
                                            ))}
                                                {
                                                    !isEditing &&
                                                    (meal.meal_items.filter((meal_item) => !meal_item.selected).length >= 3) &&
                                                    <Box display="flex" justifyContent="end">
                                                        <Typography
                                                            color="#2F1847"
                                                            fontSize="16px"
                                                            fontWeight="600"
                                                            variant="h1"
                                                            sx={{ mt: 2.5, mb: 2, ml: 1 }}
                                                        >
                                                            Genera una ricetta con il nostro ChefAI
                                                        </Typography>
                                                        <Checkbox
                                                            checked={generateRecipeCheckboxes[dayIndex][mealIndex]}
                                                            onChange={() => handleCheckboxGenerateRecipe(dayIndex, mealIndex)}
                                                        />
                                                    </Box>
                                                }
                                        </Box>
                                    ))}
                                </Box>










                                <Box
                                    sx={{ position: "absolute", left: "47.5%" }}
                                    mt="20px"
                                    display="flex"
                                    justifyContent="flex-end"
                                    width="50%"

                                >
                                    <Box
                                        sx={{
                                            height: "332px",
                                            borderRadius: "6px",
                                            border: "1px solid rgba(193, 199, 205, 1)",
                                        }}
                                    >
                                        <Box display="flex">
                                            <Box minWidth="250px" mt="40px" ml="40px" mr="25px">
                                                <Typography
                                                    color="#464868"
                                                    fontSize="20px"
                                                    fontWeight="700"
                                                    variant="h1"
                                                >
                                                    Macronutrienti
                                                </Typography>
                                            </Box>
                                            <Box minWidth="80px" mt="40px" ml="8px" mr="25px">
                                                <Typography
                                                    color="#464868"
                                                    fontSize="20px"
                                                    fontWeight="500"
                                                    variant="h2"
                                                    marginLeft="30px"
                                                >
                                                    {`Energia: `} <strong>{`${dailyMacronutrients[dayIndex].energy.quantity}kcal`}</strong>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <ResponsivePie
                                            colors={["#EDC047", "#4496A8", "#ED8047", "#BF2B2B", "#F2E68A", "#ffffff"]}
                                            tooltip={e => {
                                                let { datum } = e; return <BasicTooltip
                                                    id={datum.id}
                                                    value={`${datum.formattedValue}%`}
                                                    enableChip={true}
                                                    color={datum.color}
                                                />
                                            }}
                                            data={
                                                [
                                                    {
                                                        id: "Carbo",
                                                        value: dailyMacronutrients[dayIndex].carbohydrates.percentage,
                                                        label: `Carbo ${dailyMacronutrients[dayIndex].carbohydrates.quantity}g`,
                                                    },
                                                    {
                                                        id: "Proteine",
                                                        value: dailyMacronutrients[dayIndex].proteins.percentage,
                                                        label: `Proteine ${dailyMacronutrients[dayIndex].proteins.quantity}g`,
                                                    },
                                                    {
                                                        id: "Grassi",
                                                        value: dailyMacronutrients[dayIndex].fats.percentage,
                                                        label: `Grassi ${dailyMacronutrients[dayIndex].fats.quantity}g`,
                                                    },
                                                    {
                                                        id: "Alcol",
                                                        value: dailyMacronutrients[dayIndex].alcohol.percentage,
                                                        label: `Alcol ${dailyMacronutrients[dayIndex].alcohol.quantity}g`,
                                                    },
                                                    {
                                                        id: "Zuccheri",
                                                        value: dailyMacronutrients[dayIndex].sugars.percentage,
                                                        label: `Zuccheri ${dailyMacronutrients[dayIndex].sugars.quantity}g`,
                                                    },
                                                    {
                                                        id: "Fibre",
                                                        label: `Fibre ${dailyMacronutrients[dayIndex].fibers.quantity}g`,
                                                    },
                                                ]
                                            }
                                            margin={{ top: 30, right: 150, bottom: 100, left: -180 }}
                                            innerRadius={0.4}
                                            enableArcLinkLabels={false}
                                            padAngle={1}
                                            cornerRadius={5}
                                            enableArcLabels={false}
                                            theme={{ legends: { text: { fontSize: 18, fontFamily: "Geomanist"} } }}
                                            activeOuterRadiusOffset={8}
                                            legends={[
                                                {
                                                    anchor: 'right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: -10,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemTextColor: '#464868',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 16,
                                                    symbolShape: 'square',
                                                }
                                            ]}
                                        />
                                        {/* <PieChart
                        colors={[
                          "#EDC047",
                          "#4496A8",
                          "#ED8047",
                          "#BF2B2B",
                          "#F2E68D",
                          "#F2E68A",
                        ]}
                        series={[
                          {
                            data: [
                              {
                                id: 0,
                                value:
                                  dailyMacronutrients[dayIndex].carbohydrates.percentage,
                                label: "Carbo",
                              },
                              {
                                id: 1,
                                value: dailyMacronutrients[dayIndex].proteins.percentage,
                                label: "Proteine",
                              },
                              {
                                id: 2,
                                value: dailyMacronutrients[dayIndex].fats.percentage,
                                label: "Grassi",
                              },
                              {
                                id: 3,
                                value: dailyMacronutrients[dayIndex].alcohol.percentage,
                                label: "Alcol",
                              },
                              {
                                id: 4,
                                value: dailyMacronutrients[dayIndex].fibers.percentage,
                                label: "Fibre",
                              },
                              {
                                id: 5,
                                value: dailyMacronutrients[dayIndex].sugars.percentage,
                                label: "Zuccheri",
                              },
                            ],
                            innerRadius: 40,
                            outerRadius: 100,
                            paddingAngle: 0,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 110,
                            cy: 120,
                          },
                        ]}
                        height={300}
                      /> */}
                                    </Box>
                                </Box>


                            </Box>





                            
                            <Box mt="20px" ml="-15px">
                                <Button

                                    sx={{
                                        background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        padding: "8px 16px",
                                        borderRadius: "8px",
                                        color: "white",
                                        boxShadow: 'none',
                                        '&:hover, &:active, &:focus': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                    onClick={() => setOpenMealCreateModal(true)}
                                >
                                    Aggiungi pasto
                                </Button>
                            </Box>
                        </CustomTabPanel>
                    ))}
                </Box>
            </Box>
            {openMealCreateModal && <MealModal
                openModal={openMealCreateModal}
                setOpenModal={setOpenMealCreateModal}
                addMeal={handleAddMeal}
                mealWithSameMealTypeExist={mealWithSameMealTypeExist}
            ></MealModal>}
            {openMealEditModal && <MealModal
                openModal={openMealEditModal}
                setOpenModal={setOpenMealEditModal}
                addMeal={handleEditMeal}
                mealWithSameMealTypeExist={mealWithSameMealTypeExist}
                isEditing={true}
                defaultName={mealToEdit.name_it}
                defaultMealType={mealToEdit.meal_types_id}
                defaultNotes={mealToEdit.notes}
                defaultMealItems={mealToEdit.meal_items}
                defaultMacronutrients={mealToEdit.macronutrients}
                mealSelected={mealToEdit.selected}
            ></MealModal>}
        </Box>
    );
}